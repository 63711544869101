var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-base',{attrs:{"queries":_vm.queries,"loading":_vm.loading,"skeleton":"card-info-skeleton"},on:{"queries-response":_vm.onQueriesResponse}},[(_vm.showAutoConfigCard)?_c('card',{attrs:{"title":_vm.$t('domain.dns.auto_config'),"icon":"wrench"},scopedSlots:_vm._u([(_vm.dnsChanges)?{key:"buttons",fn:function(){return [(_vm.force !== null)?_c('b-form-checkbox',{model:{value:(_vm.force),callback:function ($$v) {_vm.force=$$v},expression:"force"}},[_vm._v(" "+_vm._s(_vm.$t('domain.dns.push_force'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.pushDnsChanges}},[_vm._v(" "+_vm._s(_vm.$t('domain.dns.push'))+" ")])]},proxy:true}:null],null,true)},[_c('b-alert',{attrs:{"variant":"warning"}},[_c('icon',{attrs:{"iname":"flask"}}),_vm._v(" "),_c('icon',{attrs:{"iname":"warning"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('domain.dns.info'))}})],1),(_vm.dnsChanges)?_vm._l((_vm.dnsChanges),function(ref){
var action = ref.action;
var records = ref.records;
var icon = ref.icon;
var variant = ref.variant;
return _c('div',{key:icon,staticClass:"mb-3"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v(" "+_vm._s(action)+" ")]),_c('div',{staticClass:"log"},_vm._l((records),function(ref,i){
var record = ref.name;
var spaces = ref.spaces;
var old_content = ref.old_content;
var content = ref.content;
var type = ref.type;
var managed_by_yunohost = ref.managed_by_yunohost;
return _c('div',{key:i,staticClass:"records px-2",class:{ 'ignored': managed_by_yunohost === false && _vm.force !== true },attrs:{"title":managed_by_yunohost === false && _vm.force !== true ? _vm.$t('domain.dns.auto_config_ignored') : null}},[_c('icon',{class:'text-' + variant,attrs:{"iname":icon}}),_vm._v(" "+_vm._s(record)+" "),_c('span',{staticClass:"bg-dark text-light px-1 rounded"},[_vm._v(_vm._s(type))]),_vm._v(_vm._s(spaces)+" "),(old_content)?_c('span',[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(old_content))]),_vm._v(" --> ")]):_vm._e(),_c('span',{class:{ 'text-success': old_content }},[_vm._v(_vm._s(content))])],1)}),0)])}):(_vm.dnsChanges === null)?_c('b-alert',{staticClass:"m-0",attrs:{"variant":"success"}},[_c('icon',{attrs:{"iname":"thumbs-up"}}),_vm._v(" "+_vm._s(_vm.$t('domain.dns.auto_config_ok'))+" ")],1):_vm._e(),(_vm.dnsErrors && _vm.dnsErrors.length)?_vm._l((_vm.dnsErrors),function(ref,i){
var variant = ref.variant;
var icon = ref.icon;
var message = ref.message;
return _c('b-alert',{key:i,class:_vm.dnsErrors.length === 1 ? 'm-0' : '',attrs:{"variant":variant}},[_c('icon',{attrs:{"iname":icon}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(message)}})],1)}):_vm._e(),(_vm.force !== null)?_c('b-alert',{attrs:{"variant":"warning"}},[_c('icon',{attrs:{"iname":"warning"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('domain.dns.push_force_warning'))}})],1):_vm._e()],2):_vm._e(),(_vm.showAutoConfigCard && _vm.dnsZone && _vm.dnsZone.length)?_c('card',{attrs:{"title":_vm.$t('domain.dns.auto_config_zone'),"icon":"globe","no-body":""}},[_c('div',{staticClass:"log"},_vm._l((_vm.dnsZone),function(ref,i){
var record = ref.name;
var spaces = ref.spaces;
var content = ref.content;
var type = ref.type;
return _c('div',{key:'zone-' + i,staticClass:"records"},[_vm._v(" "+_vm._s(record)+" "),_c('span',{staticClass:"bg-dark text-light px-1 rounded"},[_vm._v(_vm._s(type))]),_vm._v(_vm._s(spaces)+" "),_c('span',[_vm._v(_vm._s(content))])])}),0)]):_vm._e(),(_vm.showManualConfigCard)?_c('card',{attrs:{"title":_vm.$t('domain.dns.manual_config'),"icon":"globe","no-body":""}},[_c('b-alert',{staticClass:"m-0",attrs:{"variant":"warning"}},[_c('icon',{attrs:{"iname":"warning"}}),_vm._v(" "+_vm._s(_vm.$t('domain_dns_conf_is_just_a_recommendation'))+" ")],1),_c('pre',{staticClass:"log"},[_vm._v(_vm._s(_vm.dnsConfig))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }